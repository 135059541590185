import React from "react";
import { merge } from "lodash";

const defaultState = {
  listLoading: true,
  selected: null,
  selectedAction: null,
  list: [],
  count: 0,
  dummyRefresh: false,
  currentTab: "0",
};

const ListStateContext = React.createContext();
const ListDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        listLoading: action.listLoading,
      };
    case "SET_LIST":
      return {
        ...state,
        list: action.list,
      };
    case "SET_COUNT":
      return {
        ...state,
        count: action.count,
      };
    case "SET_LIST_DATA":
      return {
        ...state,
        count: action.count,
        list: action.list,
        listLoading: action.listLoading,
      };
    case "SET_SELECTED":
      return {
        ...state,
        selected: action.selected,
      };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.currentTab,
        selected: [],
      };
    case "SET_SELECTED_ACTION":
      return {
        ...state,
        selectedAction: action.selectedAction,
      };
    case "SET_DUMMY_REFRESH":
      return {
        ...state,
        dummyRefresh: action.dummyRefresh,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ListProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(userReducer, mergedState);
  return (
    <ListStateContext.Provider value={state}>
      <ListDispatchContext.Provider value={dispatch}>
        {children}
      </ListDispatchContext.Provider>
    </ListStateContext.Provider>
  );
}

function useListState() {
  const context = React.useContext(ListStateContext);
  if (context === undefined) {
    throw new Error("useListState must be used within a ListProvider");
  }
  return context;
}

function useListDispatch() {
  const context = React.useContext(ListDispatchContext);
  if (context === undefined) {
    throw new Error("useListDispatch must be used within a ListProvider");
  }
  return context;
}

export { ListProvider, useListState, useListDispatch };
