import PageHeader from "../../components/common/PageHeader/pageHeader";
import React from "react";
import { WorkticketModalProvider } from "./context/WorkticketModalContext";
import AccountPayablesList from "../reportAccountPayableList/accountPayablesList";

export function AccountPayableSubWrapper() {
  return (
    <>
      <PageHeader
        pageTitle="Account Payable"
        link="/financial/report/account-payable"
      />
      <WorkticketModalProvider>
        <AccountPayablesList />
      </WorkticketModalProvider>
    </>
  );
}
