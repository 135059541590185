import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { useWorkticketModal } from "./context/WorkticketModalContext";
export function VariousTextComponent({ id, subcontractor }) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  function toggleWorkticketModal(open, id, subcontractor = false) {
    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: id,
      subcontractor: subcontractor,
    });
  }
  const classes = useStyles();
  return (
    <>
      <Typography
        onClick={() => {
          toggleWorkticketModal(true, id, subcontractor);
        }}
        className={classes.variousText}
      >
        Various
      </Typography>
    </>
  );
}
