export function WTIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 16.4444L0 17.7778V0L1.33333 1.33333L2.66667 0L4 1.33333L5.33333 0L6.66667 1.33333L8 0L9.33333 1.33333L10.6667 0L12 1.33333L13.3333 0L14.6667 1.33333L16 0V17.7778L14.6667 16.4444L13.3333 17.7778L12 16.4444L10.6667 17.7778L9.33333 16.4444L8 17.7778L6.66667 16.4444L5.33333 17.7778L4 16.4444L2.66667 17.7778L1.33333 16.4444ZM13.3333 4.44444V6.22222H2.66667V4.44444H13.3333ZM2.66667 8V9.77778H13.3333V8H2.66667ZM2.66667 11.5556V13.3333H13.3333V11.5556H2.66667Z"
        fill="white"
      />
      <mask
        id="mask0_1244_8034"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33333 16.4444L0 17.7778V0L1.33333 1.33333L2.66667 0L4 1.33333L5.33333 0L6.66667 1.33333L8 0L9.33333 1.33333L10.6667 0L12 1.33333L13.3333 0L14.6667 1.33333L16 0V17.7778L14.6667 16.4444L13.3333 17.7778L12 16.4444L10.6667 17.7778L9.33333 16.4444L8 17.7778L6.66667 16.4444L5.33333 17.7778L4 16.4444L2.66667 17.7778L1.33333 16.4444ZM13.3333 4.44444V6.22222H2.66667V4.44444H13.3333ZM2.66667 8V9.77778H13.3333V8H2.66667ZM2.66667 11.5556V13.3333H13.3333V11.5556H2.66667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1244_8034)">
        <rect
          x="-15.1113"
          y="-20.4443"
          width="56.8889"
          height="56.8889"
          fill="#D9D9D9"
        />
      </g>
    </svg>
  );
}
