import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { formatNumber } from "../../components/util/stringFormat";
import React, { useContext, useState } from "react";
import { useAddDiscountModal } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";
import GlobalUiContext from "../../contexts/globalUiContext";
import { hasPermission, permissionFinancial } from "../../lib/permissions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import { DeleteBin } from "../partnerPage/icons/DeleteBin";
import { EditIconBg } from "./icon/EditIconBg";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import { postDiscount } from "../../services/financialService";
import MessageDialog from "../partnersList/dialog/messageDialog";
import { useQueryClient } from "react-query";
import LoadingDialog from "../../components/ui/dialog/loadingDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import LoadingIndicator from "../../components/common/LoadingIndicator/loadingIndicator";
import FormInput from "../../components/ui/FormContent/formInput";
import DialogActions from "@material-ui/core/DialogActions";
import { ButtonBase } from "../../components/ui/core/button";
import {
  workticketBillOverride,
  workticketPaymentOverride,
} from "../../services/workticketService";
import { logException } from "../../components/util/logUtil";
import { OutlineReceiptIcon } from "../reportAccountPayableWorkticketsInternal/icons/OutlineReceiptIcon";
import { ExclamationMarkIcon } from "./icon/ExclamationMarkIcon";
import { useWorkticketModal } from "../reportAccountPayableWorkticketsInternal/context/WorkticketModalContext";
export function AccountPayableDiscountItem({
  classes,
  workticketMainId,
  workticket,
  customerName,
  paymentId,
  totalAmount,
  isPayment,
  permAP,
  permAR,
  mode,
  hideDiscount = false,
  userName,
  amountBilled,
  isRecurrent = false,
  paymentWTid,
  canChangeBilling,
  canChangePayment,
}) {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  function toggleWorkticketModal(open, id, subcontractor = false) {
    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: id,
      subcontractor: subcontractor,
      fromWTPage: true,
    });
  }
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const toggleDiscountModal = (open, selected, adding = false) => {
    if (adding) {
      dispatchDiscountAction({
        type: "TOGGLE_CONTACT_DIALOG",
        open,
        selected,
      });
    } else {
      const discount = workticket?.discount;
      const editing = true;
      dispatchDiscountAction({
        type: "TOGGLE_CONTACT_DIALOG",
        open,
        selected,
        discount,
        editing,
      });
    }
  };

  const queryClient = useQueryClient();
  async function deleteDiscount() {
    let dataSend = {
      ap_record_ids: array,
      discount: 0,
    };
    try {
      setIsConfirmDialogOpen(false);
      setLoading(true);
      let response = await postDiscount(dataSend);
      setLoading(false);
      setIsSuccessDialogOpen(true);
      await queryClient.invalidateQueries("workticket");
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
      setIsErrorDialogOpen(true);
    }
  }
  const canEditDiscount =
    role?.category !== "subcontractor" &&
    hasPermission(permissionFinancial.AP_WORKTICKET_DISCOUNTS, permissions);
  const hideMenuPayment = !canEditDiscount && !canChangePayment;
  const menuItems = [
    {
      label: `Edit ${mode}`,
      icon: <EditIconBg fontSize="inherit" />,
      onClick: () => {
        setAmountUpdate(workticket.amount_due);
        setOpenUpdate(true);
        handleMenuClose();
      },
      disabled: loading,
      hide: mode === "Payment" && !canChangePayment,
    },
    {
      label: "Add Discount",
      icon: <OutlineReceiptIcon fontSize="inherit" />,
      hide:
        hideDiscount ||
        workticket?.discount > 0 ||
        !canEditDiscount ||
        !canChangePayment,
      onClick: () => {
        const strTest = isPayment ? "PP-" : "AP-";
        const array = [strTest + (paymentId ? workticket?.id : workticket?.id)];
        toggleDiscountModal(true, array, true);
        handleMenuClose();
      },
    },
    {
      label: "Edit Discount",
      icon: <EditIconBg fontSize="inherit" />,
      onClick: () => {
        const strTest = isPayment ? "PP-" : "AP-";
        const array = [strTest + (paymentId ? workticket?.id : workticket?.id)];
        toggleDiscountModal(true, array);
        handleMenuClose();
      },
      disabled: loading,
      hide:
        hideDiscount ||
        workticket?.discount <= 0 ||
        !canEditDiscount ||
        !canChangePayment,
    },
    {
      label: "Delete Discount",
      hide: hideDiscount || !canEditDiscount || !canChangePayment,
      icon: <DeleteBin fontSize="inherit" />,
      onClick: async () => {
        const strTest = isPayment ? "PP-" : "AP-";
        const array = [strTest + (paymentId ? workticket?.id : workticket?.id)];
        setArray(array);
        setIsConfirmDialogOpen(true);
        handleMenuClose();
      },
      disabled: loading,
    },
  ];
  const [openUpdate, setOpenUpdate] = useState(false);
  const [amountUpdate, setAmountUpdate] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const handleUpdateBilling = async () => {
    try {
      setIsLoadingUpdate(true);
      if (mode === "Payment") {
        await workticketPaymentOverride(workticketMainId, {
          payment_per_instance: amountUpdate,
        });
        // workticket.payment.total_amount = Number(amountUpdate);
      }

      if (mode === "Billing") {
        await workticketBillOverride(workticketMainId, {
          bill_per_instance: amountUpdate,
        });
        // workticket.bill.total_amount = Number(amountUpdate);
      }
      // dispatchContext({
      //   type: "SET_WORKTICKET",
      //   workticket: workticket,
      // });
      await queryClient.invalidateQueries("workticket");
      setIsLoadingUpdate(false);
      setOpenUpdate(false);
    } catch (e) {
      logException(e, `Cannot update ${mode}`);
    }
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleChangeAmount = (event) => {
    setAmountUpdate(event.value);
  };
  return (
    <>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        message={"Are you sure you want to delete the discount?"}
        title="Delete Discount"
        handleClose={handleCloseConfirmDialog}
        handleConfirm={async () => {
          // await deleteDiscount(row.id);
          // await handleToggleConfirmDialog(true);
        }}
      />
      <Box className={classes.infoAccountContainer}>
        <Box className={classes.labelsFirstContainer}>
          <Box className={classes.labelsContainer}>
            <Typography className={classes.labelBoldText}>
              {userName ? userName : "Customer"}:
            </Typography>
            <Typography className={classes.labelInfoValueText}>
              {customerName ? customerName : workticket?.customer_name}
            </Typography>
          </Box>

          {mode === "Billing" && canChangeBilling && (
            <Box
              onClick={(event) => {
                handleMenuClick(event);
              }}
              className={classes.editButton}
            >
              <ThreeDots />
            </Box>
          )}
          {mode === "Payment" && hideMenuPayment && (
            <Box
              onClick={(event) => {
                handleMenuClick(event);
              }}
              className={classes.editButton}
            >
              <ThreeDots />
            </Box>
          )}
          {/*{role?.category !== "subcontractor" &&*/}
          {/*  hasPermission(*/}
          {/*    permissionFinancial.AP_WORKTICKET_DISCOUNTS,*/}
          {/*    permissions*/}
          {/*  ) && (*/}

          {/*  )}*/}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {menuItems.map(({ label, icon, onClick, disabled, hide }) => (
              <MenuItem
                key={label}
                onClick={onClick}
                style={{
                  display: hide ? "none" : "flex",
                }}
                disabled={loading}
              >
                <Box className={classes.menuIcon}>{icon}</Box>
                <Typography variant="caption" className={classes.menuText}>
                  {label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box className={classes.labelsContainer}>
          <Typography className={classes.labelBoldText}>
            {hideDiscount ? "Total" : "Payment"}
          </Typography>
          <Typography className={classes.labelInfoValueText}>
            {/*${formatNumber(workticket?.net_total)}*/}
            {totalAmount
              ? `$${formatNumber(totalAmount)}`
              : `$${formatNumber(workticket?.net_total)}`}
          </Typography>
        </Box>
        {hideDiscount && (
          <Box className={classes.labelsContainer}>
            <Typography className={classes.labelBoldText}>Billed:</Typography>
            <Typography className={classes.labelInfoValueText}>
              ${formatNumber(amountBilled)}
            </Typography>
          </Box>
        )}

        {!hideDiscount && (
          <>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Discount:
              </Typography>
              <Typography className={classes.labelInfoValueText}>
                {workticket?.discount
                  ? `${parseFloat(workticket?.discount)}%`
                  : "0%"}
              </Typography>
            </Box>
            <Box className={classes.labelsContainer}>
              <Typography className={classes.labelBoldText}>
                Total After Discount:
              </Typography>

              <Typography
                className={
                  parseFloat(workticket?.discount) > 0
                    ? classes.labelGreenText
                    : classes.labelInfoValueText
                }
              >
                {formatNumber(parseFloat(workticket?.net_due).toFixed(2))}
              </Typography>
            </Box>
            {isRecurrent && (
              <>
                <Box className={classes.noticeContainer}>
                  <Box className={classes.noticeExclamationIcon}>
                    <ExclamationMarkIcon />
                  </Box>
                  <Typography className={classes.noticeText}>
                    The payment for this ticket is part of a monthly billing.
                    See the other Worktickets that are part of this payment{" "}
                    <span
                      onClick={() => {
                        toggleWorkticketModal(true, paymentWTid, true);
                      }}
                      className={classes.hereLinkText}
                    >
                      here
                    </span>
                    .
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        message={"Are you sure you want to delete the discount?"}
        title="Delete Discount"
        handleClose={handleCloseConfirmDialog}
        handleConfirm={async () => {
          await deleteDiscount();
        }}
      />
      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message="The discount has been successfully deleted."
        handleClose={() => {
          setIsSuccessDialogOpen(false);
        }}
      />
      <LoadingDialog open={loading} />
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseUpdate}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>

          <Box className={classes.formIconDialog}>
            <TicketIcon className={classes.iconDialog} />
          </Box>
          <Typography
            variant="h4"
            className={classes.formTitleDialog}
            gutterBottom
          >
            Workticket {mode}
          </Typography>
          {isLoadingUpdate ? (
            <LoadingIndicator />
          ) : (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="amount"
              label={"Amount"}
              value={amountUpdate}
              handleBlur={handleChangeAmount}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.actionsBillingDialogWrapper}>
          <ButtonBase
            variant="outlined"
            color="secondary"
            onClick={handleCloseUpdate}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            color="secondary"
            onClick={handleUpdateBilling}
            disabled={isLoadingUpdate}
          >
            Update
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </>
  );
}
