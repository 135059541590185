import React, { Fragment } from "react";
import { TableProvider } from "../../../contexts/tableContext";
import { WorkticketModal } from "../../reportAccountPayableWorkticketsInternal/WorkticketModal";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorkticketModalContext = React.createContext();
const initialState = {
  isWorkticketModalOpen: false,
  activeRowId: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_WORKTICKET_MODAL":
      console.log("TOGGLE_WORKTICKET_MODAL", action.open);
      return {
        ...state,
        isWorkticketModalOpen: action.open,
        activeRowId: action.open ? action.activeRowId : null,
      };
    default:
      return state;
  }
};
const WorkticketModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <WorkticketModalContext.Provider value={[state, dispatch]}>
      {children}
      {state.isWorkticketModalOpen && (
        <Fragment key={state.activeRowId}>
          <TableProvider>
            {/*<WorkticketModal rowId={state.activeRowId} subcontractor={true} />*/}
          </TableProvider>
        </Fragment>
      )}
    </WorkticketModalContext.Provider>
  );
};
const useWorkticketModal = () => {
  const context = React.useContext(WorkticketModalContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketModal must be used within a WorkticketModalProvider"
    );
  }
  return context;
};

export { WorkticketModalProvider, useWorkticketModal };
