import React, { Fragment, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addWorkticketComment,
  addWorkticketCommentW,
  deleteWorkticketComment,
  deleteWorkticketCommentW,
  getWorkticketComments,
} from "../../services/workticketService";
import { useWorkticketView } from "../../contexts/workticketViewContext";
import { addNewPartnerComment } from "../../services/partners/commentsService";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { CommentItemComponent } from "./CommentItemComponent";
import MessageDialog from "../workticketPage/dialog/successDialog";
import LoadingIndicator from "../../components/common/LoadingIndicator/loadingIndicator";
import Skeleton from "@material-ui/lab/Skeleton";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import { logException } from "../../components/util/logUtil";
import { hasPermission, permissionWorkticket } from "../../lib/permissions";
import GlobalUiContext from "../../contexts/globalUiContext";
const INTERNAL = "internal";
const PARTNER = "partner";
const CUSTOMER = "customer";
export function AddWorkticketCommentDialogue() {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const {
    isEditingComment,
    isOpenAddDialog,
    workticketId,
    commentText,
    successEdit,
    isDeletingComment,
    commentId,
    commentIdDelete,
  } = commentAction;
  const initialState = {
    comment: isEditingComment ? commentText : "",
  };
  const [data, setData] = useState(initialState);
  const toggleModal = (open) => {
    dispatchCommentAction({ type: "TOGGLE_COMMENT_DIALOG", open });
  };
  const [subTab, setSubTab] = useState(INTERNAL);
  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };
  const context = useWorkticketView();
  const dispatchContext = context[1];
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const closeMessage = () => {
    if (!loadingState) {
      setOpenMessage(false);
    }
  };
  const closeConfirm = () => {
    setOpenConfirm(false);
  };
  const setWorkTicketComments = (comments) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
    dispatchContext({
      type: "SET_LOADING",
      isLoading: false,
    });
  };
  //if successEdit is true, open success the dialog
  // if (successedit) {
  //   setopenmessage(true);
  //   setopenmessagecontent("comment edited successfully");
  // }
  useEffect(() => {
    if (successEdit === true) {
      setOpenMessage(true);
      setOpenMessageContent("Comment edited successfully");
      dispatchContext({
        type: "TOGGLE_SUCCESS_EDIT",
        open: null,
      });
    }
  }, [commentAction]);
  useEffect(() => {
    if (isDeletingComment === true) {
      //open confirm dialog
      setOpenConfirm(true);
    }
  }, [commentAction]);
  const [loadingState, setLoadingState] = useState(false);
  const { data: commentsData, isLoading } = useQuery(
    ["workTicketComments", workticketId],
    async () => {
      const { data } = await getWorkticketComments(workticketId);
      setWorkTicketComments(data.data);
      return data.data;
    },
    {
      enabled: !!workticketId,
    }
  );
  const addComment = useMutation(addWorkticketCommentW, {
    onSuccess: () => {
      queryClient.invalidateQueries("workTicketComments");
      setLoadingState(false);
      setOpenMessage(true);
      setOpenMessageContent("Comment added successfully");
      setData({ comment: "" });
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const deleteComment = useMutation(deleteWorkticketCommentW, {
    onSuccess: () => {
      queryClient.invalidateQueries("workTicketComments");
      setLoadingState(false);
      setOpenConfirm(false);
      setOpenMessage(true);
      setOpenMessageContent("Comment deleted successfully");
      dispatchCommentAction({
        type: "TOGGLE_SUCCESS_DELETE",
        open: true,
      });
      dispatchCommentAction({ type: "SET_LOADING", isActionLoading: false });
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const [isEditing, setIsEditing] = useState(false);
  const internalComments = commentsData?.internal;
  const partnerComments = commentsData?.partner;
  const customerComments = commentsData?.customer;
  async function handleDeleteComment(commentId) {
    setLoadingState(true);
    setOpenConfirm(false);
    dispatchCommentAction({ type: "SET_LOADING", isActionLoading: true });
    deleteComment.mutate({
      id: workticketId,
      id_comment: commentId,
    });
  }
  const handleSubmitComment = () => {
    setLoadingState(true);
    addComment.mutate({
      workticket_id: workticketId,
      data: {
        body: data.comment,
        tab: subTab,
      },
    });

    // let tab = "";
    // switch (value) {
    //   case 0:
    //     tab = "internal";
    //     break;
    //   case 1:
    //     tab = "partner";
    //     break;
    //   default:
    // }
    // setLoadingState(true);
    // if (isEditing) {
    //   const data = {
    //     data: {
    //       body: comment,
    //       tab,
    //     },
    //     id: commentToEdit.id,
    //     partner_id: userId,
    //   };
    //   updateComment.mutate(data);
    // } else {
    //   addPartnerComment.mutate({
    //     partner_id: userId,
    //     data: {
    //       body: searched,
    //       tab,
    //     },
    //   });
    //   setSearched("");
    // }
  };
  return (
    <Fragment>
      <Dialog open={isOpenAddDialog}>
        <DialogTitle
          className={classes.dialogTitleWrapperComment}
          id="form-dialog-title"
        >
          <Box className={classes.commentTitleInnerWrapper}>
            <Box className={classes.titleIconWorkticketID}>
              <ReceiptIcon />
              <Typography className={classes.dialogTitle}>
                Workticket - {workticketId}
              </Typography>
            </Box>
            <Typography className={classes.addCommentTitle}>
              Add a comment
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              toggleModal(false);
            }}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.addContactFormWrapper}>
          <TabContext value={subTab}>
            {isLoading && (
              <Skeleton animation="wave" variant="rect" height={100} />
            )}
            {!isEditing && !isLoading && !loadingState && (
              <>
                <></>
                <Box className={classes.commentsVerticalContainer}>
                  {subTab === INTERNAL && (
                    <>
                      {internalComments?.map((comment, index) => (
                        <CommentItemComponent
                          workticketId={workticketId}
                          tab={subTab}
                          key={index}
                          comment={comment}
                          index={index}
                          setIsEditing={() => setIsEditing(true)}
                          classes={classes}
                        />
                      ))}
                    </>
                  )}
                  {subTab === PARTNER && (
                    <>
                      {partnerComments?.map((comment, index) => (
                        <CommentItemComponent
                          key={index}
                          comment={comment}
                          workticketId={workticketId}
                          tab={subTab}
                          index={index}
                          setIsEditing={() => setIsEditing(true)}
                          classes={classes}
                        />
                      ))}
                    </>
                  )}
                  {subTab === CUSTOMER && (
                    <>
                      {customerComments?.map((comment, index) => (
                        <CommentItemComponent
                          key={index}
                          tab={subTab}
                          comment={comment}
                          workticketId={workticketId}
                          index={index}
                          setIsEditing={() => setIsEditing(true)}
                          classes={classes}
                        />
                      ))}
                    </>
                  )}
                </Box>
              </>
            )}
            {loadingState && <LoadingIndicator />}
            <Box className={classes.containerSubTabs}>
              <TabList onChange={handleSubTabChange}>
                {hasPermission(
                  permissionWorkticket.COMMENTS_INTERNAL,
                  permissions
                ) && (
                  <Tab
                    label={`Internal`}
                    value={INTERNAL}
                    className={classes.activityTab}
                  />
                )}
                {hasPermission(
                  permissionWorkticket.COMMENTS_PARTNER,
                  permissions
                ) && (
                  <Tab
                    label={`Partners`}
                    value={PARTNER}
                    className={classes.activityTab}
                  />
                )}
                {hasPermission(
                  permissionWorkticket.COMMENTS_CUSTOMER,
                  permissions
                ) && (
                  <Tab
                    label={`Customers`}
                    value={CUSTOMER}
                    className={classes.activityTab}
                  />
                )}
              </TabList>
            </Box>
          </TabContext>

          <TextField
            // className={classes.commentEditField}
            classes={{ root: classes.writeCommentField }}
            autoFocus={true}
            name="comment"
            value={data.comment}
            // handleBlur={handleBlur}
            onChange={(e) => setData({ ...data, comment: e.target.value })}
            InputProps={{ disableUnderline: true }}
            // defaultValue={question.question.question}
            placeholder={"Write a comment"}
            multiline
            minRows={4}
          />
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            className={classes.addDiscountCancelButton}
            onClick={() => toggleModal(false)}
            disabled={loadingState}
          >
            Cancel
          </Button>
          <Button
            className={classes.secondAddDiscountButton}
            disabled={loadingState || data.comment.length === 0}
            onClick={handleSubmitComment}
          >
            {/*{isEditing ? "Edit" : "Add"} Contact*/}
            Add Comment
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={loadingState}
      />
      {!loadingState && (
        <ConfirmDialog
          open={openConfirm}
          handleConfirm={() => handleDeleteComment(commentIdDelete)}
          handleClose={closeConfirm}
          message={"Are you sure you want to delete this comment?"}
          title={`Workticket ${workticketId}`}
        />
      )}
    </Fragment>
  );
}
