export function CommentOutline() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 7.09253C17.6117 7.32957 18.125 8.03267 18.125 8.84018V12.4115C18.125 13.3587 17.4188 14.1619 16.4748 14.2389C16.1923 14.262 15.9091 14.2821 15.625 14.2994V16.875L13.125 14.375C11.9969 14.375 10.8797 14.329 9.77519 14.2388C9.5266 14.2186 9.2945 14.1479 9.08745 14.0375M16.875 7.09253C16.7488 7.05193 16.6161 7.025 16.4783 7.01356C15.3727 6.9218 14.2543 6.875 13.125 6.875C11.9957 6.875 10.8773 6.9218 9.77174 7.01356C8.82916 7.09179 8.125 7.89436 8.125 8.84018V12.4114C8.125 13.1092 8.50822 13.7288 9.08745 14.0375M16.875 7.09253V5.53109C16.875 4.17991 15.9152 3.00887 14.5753 2.83492C12.8732 2.61396 11.1375 2.5 9.37524 2.5C7.61278 2.5 5.87694 2.61399 4.1747 2.83499C2.83477 3.00895 1.875 4.17998 1.875 5.53115V10.7189C1.875 12.07 2.83478 13.2411 4.1747 13.415C4.65551 13.4774 5.13899 13.5313 5.625 13.5765V17.5L9.08745 14.0375"
        stroke="#747474"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
