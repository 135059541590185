import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, { useContext, useMemo } from "react";
import { CommentItem } from "../partnerPage/Comments/CommentItem";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import { useAddDiscountModal } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";
import { AddDiscountDialogue } from "../reportAccountPayableWorkticketsInternal/AddDiscountDialogue";
import Button from "@material-ui/core/Button";
import { formatNumber } from "../../components/util/stringFormat";
import { useQueryClient } from "react-query";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import GlobalUiContext from "../../contexts/globalUiContext";
import { AccountPayableDiscountItem } from "./AccountPayableDiscountItem";
import Alert from "@material-ui/lab/Alert";
const INTERNAL = "internal";
const PARTNER = "partner";
const CUSTOMER = "customer";
export function EditIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_164_6895)">
        <path
          d="M3.33301 14.0448V16.2964C3.33301 16.5038 3.49595 16.6667 3.70333 16.6667H5.95487C6.05115 16.6667 6.14743 16.6297 6.21409 16.5556L14.3019 8.47525L11.5245 5.69786L3.4441 13.7782C3.37004 13.8523 3.33301 13.9412 3.33301 14.0448ZM16.4497 6.3274C16.7386 6.03855 16.7386 5.57195 16.4497 5.2831L14.7166 3.55001C14.4278 3.26116 13.9612 3.26116 13.6723 3.55001L12.3169 4.90538L15.0943 7.68277L16.4497 6.3274Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_164_6895">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 132,
      width: "100%",
      backgroundColor: "#4F98BC",
    },
  },
})((props) => (
  <Tabs
    orientation={"horizontal"}
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: "90px",
    textAlign: "center",
    alignItems: "center",
    "&. MuiTab-root": {
      minWidth: "90px",
    },
    "&:hover": {
      color: "#4F98BC",
      opacity: 1,
    },
    "&$selected": {
      color: "#4F98BC",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4F98BC",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);
export function AccountReceivablePayable({ workticket, worktickedId }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );
  const permAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AP, permissions),
    [permissions]
  );
  const permAR = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AR, permissions),
    [permissions]
  );
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const readOnlyWorkticket =
    workticket?.recurrence_instance_id && !workticket?.instance_override;
  const canChangeBilling =
    !readOnlyWorkticket &&
    Number(workticket?.bill?.amount_billed) === 0 &&
    [0, 1].includes(workticket?.bill?.status);
  const canChangePayment =
    !readOnlyWorkticket &&
    Number(workticket?.payment?.amount_paid) === 0 &&
    [0, 1].includes(workticket?.payment.status) &&
    !permOnlyAP;
  return (
    <>
      <Box className={classes.payableReceivableMainContainer}>
        <StyledTabs
          value={value}
          onChange={handleChangeTab}
          aria-label="styled tabs"
        >
          {permAR && <StyledTab label="Account Receivable" {...a11yProps(0)} />}
          {(permAP || permOnlyAP) && (
            <StyledTab label="Account Payable" {...a11yProps(1)} />
          )}
        </StyledTabs>

        <TabPanel value={value} index={0}>
          <Box className={classes.discountsHolderContainer}>
            {workticket?.bill?.id && (
              <>
                {[0, 1].includes(workticket.status) &&
                workticket.bill.status === 4 &&
                !readOnlyWorkticket ? (
                  <Alert severity="error" className={classes.infoAlert}>
                    Contact support/financial to update amounts.
                  </Alert>
                ) : null}
                <AccountPayableDiscountItem
                  userName={"Customer"}
                  permAR={permAR}
                  permAP={permAP}
                  hideDiscount={true}
                  mode={"Billing"}
                  workticketMainId={workticket?.id}
                  classes={classes}
                  customerName={workticket?.bill?.customer_name}
                  workticket={workticket.bill}
                  paymentId={workticket?.bill?.job_id}
                  totalAmount={workticket?.bill?.net_total}
                  amountBilled={workticket?.bill?.amount_billed}
                  isPayment={true}
                  canChangeBilling={canChangeBilling}
                />
              </>
            )}
            {!workticket?.bill && (
              <Typography variant="subtitle2" className={classes.emptyBilling}>
                No Billing
              </Typography>
            )}
            {workticket?.ar_records?.length > 0 && (
              <>
                {workticket?.ap_records?.map((apRecord, index) => (
                  <AccountPayableDiscountItem
                    key={index}
                    classes={classes}
                    workticket={apRecord}
                    permAR={permAR}
                    permAP={permAP}
                  />
                ))}
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box className={classes.discountsHolderContainer}>
            {workticket?.payment?.id && (
              <>
                {[0, 1].includes(workticket.status) &&
                workticket.payment.status === 4 &&
                !permOnlyAP ? (
                  <Alert severity="error" className={classes.infoAlert}>
                    Contact support/financial to update amounts.
                  </Alert>
                ) : null}
                <AccountPayableDiscountItem
                  classes={classes}
                  userName={"Subcontractor"}
                  customerName={workticket?.payment?.user_name}
                  paymentWTid={workticket?.payment?.id}
                  workticket={workticket.payment}
                  workticketMainId={workticket?.id}
                  paymentId={workticket?.payment?.job_id}
                  mode={"Payment"}
                  isRecurrent={
                    workticket?.preventative_service[0]?.is_recurring
                  }
                  totalAmount={workticket?.payment?.net_total}
                  isPayment={true}
                  permAR={permAR}
                  permAP={permAP}
                  canChangePayment={canChangePayment}
                />
              </>
            )}
            {!workticket?.payment && (
              <Typography variant="subtitle2" className={classes.emptyBilling}>
                No Payment
              </Typography>
            )}
            {workticket?.ap_records?.length > 0 && (
              <>
                {workticket?.ap_records?.map((apRecord, index) => (
                  <AccountPayableDiscountItem
                    key={index}
                    classes={classes}
                    workticket={apRecord}
                    permAR={permAR}
                    permAP={permAP}
                  />
                ))}
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
      {isOpenAddDialog && (
        <AddDiscountDialogue workTicketModal={true} filterState={null} />
      )}
    </>
  );
}
