import React from "react";
import * as NavIcon2 from "../Icons/navIconsV2";
import CustomerIcon from "@material-ui/icons/RecentActors";
import DefaultIcon from "@material-ui/icons/FiberManualRecord";
import FormsIcon from "@material-ui/icons/ListAlt";
import TicketIcon from "@material-ui/icons/Receipt";
import UsersIcon from "@material-ui/icons/AccountBox";
import LearnIcon from "assets/icons/learnIcon";

import {
  permissionAdmin,
  permissionAsset,
  permissionBudget,
  permissionDashboard,
  permissionFinancial,
  permissionProject,
  permissionQuote,
  permissionRequest,
  permissionSurface,
  permissionWorkticket,
  permissionForms,
  permissionQuality,
  permissionCustomer,
  permissionLearn,
  permissionPartner,
} from "lib/permissions";
import PartnerIcon from "assets/icons/partnerIcon";

const user = JSON.parse(localStorage.getItem("user"));

const navItemsDefault = [
  {
    id: 25,
    name: "Dashboard",
    link: "/dashboard",
    icon: <NavIcon2.Home />,
    permission: permissionDashboard.MAP,
    childs: [
      {
        parentId: 25,
        name: "Map",
        link: "/dashboard",
        icon: <NavIcon2.Home />,
        permission: permissionDashboard.MAP,
      },
      {
        parentId: 25,
        name: "KPI Dashboard",
        link: "/dashboard-kpi",
        icon: <NavIcon2.Kpi />,
        permission: permissionDashboard.KPI,
      },
      {
        parentId: 25,
        name: "Performance Board",
        link: "/dashboard-kpi/leaderboard",
        icon: <NavIcon2.Kpi />,
        permission: permissionDashboard.LEADERBOARD,
      },
      {
        parentId: 25,
        name: "KPI Dashboard",
        link: "/dashboard-kpi",
        icon: <NavIcon2.Kpi />,
        permission: permissionDashboard.KPI_PARTNER,
      },
      {
        parentId: 25,
        name: "Notes",
        link: "/notes",
        icon: <NavIcon2.Note />,
        permission: permissionDashboard.NOTES,
      },
    ],
  },
  {
    id: 26,
    name: "Dashboard",
    link: "/dashboard",
    icon: <NavIcon2.Home />,
    permission: permissionSurface.MAP,
    childs: [
      {
        parentId: 26,
        name: "Map",
        link: "/dashboard",
        icon: <NavIcon2.Home />,
        permission: permissionSurface.MAP,
      },
      {
        parentId: 26,
        name: "KPI Dashboard",
        link: "/dashboard-kpi",
        icon: <NavIcon2.Kpi />,
        permission: permissionSurface.KPI,
      },
      {
        parentId: 26,
        name: "Notes",
        link: "/notes",
        icon: <NavIcon2.Note />,
        permission: permissionSurface.NOTES,
      },
    ],
  },
  {
    id: 0,
    name: "Projects",
    link: "/projects",
    icon: <NavIcon2.Project />,
    permission: permissionProject.MODULE,
  },
  {
    id: 1,
    name: "Work",
    link: "/work",
    icon: <NavIcon2.Work />,
    childs: [
      {
        parentId: 1,
        name: "Requests",
        link: "/requests",
        icon: <NavIcon2.Request />,
        permission: permissionRequest.MODULE,
      },
      {
        parentId: 1,
        name: "Quotes",
        link: "/quotes",
        icon: <NavIcon2.Quote />,
        permission: permissionQuote.MODULE,
      },
      {
        parentId: 1,
        name: "Worktickets",
        link: "/worktickets",
        icon: <NavIcon2.Workticket />,
        permission: permissionWorkticket.MODULE,
      },
      {
        parentId: 1,
        name: "Assets",
        link: "/assets",
        icon: <NavIcon2.Asset />,
        permission: permissionAsset.MODULE,
      },
      {
        parentId: 1,
        name: "Forms",
        link: `/forms?talent`,
        icon: <FormsIcon />,
        permission: permissionForms.FORMS_MODULE,
      },
    ],
  },
  {
    id: 27,
    name: "Quality",
    link: "/surveys",
    icon: <NavIcon2.Quality />,
    childs: [
      {
        parentId: 27,
        name: "Surveys",
        link: "/surveys",
        icon: <DefaultIcon />,
        permission: permissionQuality.VIEW,
      },
      {
        parentId: 27,
        name: "Management",
        link: "/surveys/admin/surveys",
        icon: <DefaultIcon />,
        permission: permissionQuality.MANAGE,
      },
    ],
  },
  {
    id: 12,
    name: "Learn",
    link: `/learn`,
    icon: <LearnIcon />,
    permission: permissionLearn.VIEW,
  },
  {
    id: 7,
    name: "Team",
    link: "/team",
    icon: <NavIcon2.Team />,
    permission: permissionDashboard.PEOPLE,
    childs: [
      {
        parentId: 7,
        name: "Team",
        link: "/team",
        icon: <DefaultIcon />,
        permission: permissionDashboard.PEOPLE,
      },
      {
        parentId: 7,
        name: "Team",
        link: "/team",
        icon: <DefaultIcon />,
        permission: permissionSurface.PEOPLE,
      },
    ],
  },
  {
    id: 11,
    name: "Reports",
    link: `/reports`,
    icon: <NavIcon2.Reports />,
    childs: [
      {
        parentId: 11,
        name: "Labor Reports",
        link: `/dashboard-kpi/report/labor`,
        icon: <DefaultIcon />,
        permission: permissionDashboard.LABOR_REPORT,
      },
    ],
  },
  {
    id: 8,
    name: "Financial",
    link: "/financial",
    icon: <NavIcon2.Financial />,
    childs: [
      {
        parentId: 8,
        name: "Timekeeping",
        link: "/financial/report/timekeeping",
        icon: <DefaultIcon />,
        permission: permissionWorkticket.EXPORT_TIMEKEEPING,
      },
      {
        parentId: 8,
        name: "Billing",
        link: "/financial/report/account-payable",
        icon: <DefaultIcon />,
        permission: permissionFinancial.MODULE,
      },
      {
        parentId: 8,
        name: "Subcontractors",
        link: "/financial/report/account-payable-work-sub",
        icon: <DefaultIcon />,
        permission: permissionFinancial.AP_LIST_SUB,
      },
      {
        parentId: 8,
        name: "Budgets",
        link: "/budgets",
        icon: <DefaultIcon />,
        permission: permissionBudget.MODULE,
      },
      {
        parentId: 8,
        name: "AP Supplies",
        link: `/financial/report/account-payable-supplies`,
        icon: <DefaultIcon />,
        permission: permissionFinancial.AP_LIST_SUPPLIES,
      },
    ],
  },
  {
    id: 30,
    name: "Partners",
    link: "/partners",
    icon: <PartnerIcon />,
    permission: permissionPartner.VIEW,
  },
  {
    id: 31,
    name: "Partners",
    link: `/partner/${user?.partner_id ?? 0}`,
    icon: <PartnerIcon />,
    permission: permissionPartner.PARTNER_OWNER,
  },
  {
    id: 4,
    name: "Settings",
    link: "/admin",
    icon: <NavIcon2.Setting />,
    childs: [
      {
        parentId: 4,
        name: "Users",
        link: "/users",
        icon: <UsersIcon />,
        permission: permissionAdmin.USER_LIST,
      },
      {
        parentId: 4,
        name: "Customers",
        link: "/customers",
        icon: <CustomerIcon />,
        permission: permissionCustomer.CUSTOMERS_MODULE,
      },
      {
        parentId: 4,
        name: "Assets",
        link: "/assets/admin",
        icon: <NavIcon2.Asset />,
        permission: permissionAsset.EDIT,
      },
      {
        parentId: 4,
        name: "Services",
        link: "/services-admin",
        icon: <NavIcon2.Quote />,
        permission: permissionAdmin.CUSTOMER_LIST,
      },
      {
        parentId: 4,
        name: "Worktickets",
        link: "/worknova",
        icon: <TicketIcon />,
        permission: permissionWorkticket.WORKORDERS_NOVA_ADMIN,
      },
    ],
  },
  {
    id: 20,
    name: "Worktickets",
    link: "/worktickets",
    icon: <NavIcon2.Workticket />,
    permission: permissionSurface.WORKTICKET,
  },
  {
    id: 21,
    name: "Requests",
    link: "/requests",
    icon: <NavIcon2.Request />,
    permission: permissionSurface.REQUEST,
  },
  {
    id: 22,
    name: "Quotes",
    link: "/quotes/surface",
    icon: <NavIcon2.Quote />,
    permission: permissionSurface.QUOTE,
  },
  {
    id: 24,
    name: "Quality",
    link: "/surveys",
    icon: <NavIcon2.Quality />,
    permission: permissionSurface.QUALITY,
  },
  {
    id: 23,
    name: "Assets",
    link: "/assets/surface",
    icon: <NavIcon2.Asset />,
    permission: permissionSurface.ASSETS,
  },
];

export default navItemsDefault;
