import React, { Fragment, useContext, useState } from "react";
import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { Location } from "../partnerPage/icons/Location";
import { DeleteBin } from "../partnerPage/icons/DeleteBin";
import useStyles from "./styles";
import { OutlineReceiptIcon } from "./icons/OutlineReceiptIcon";
import { CommentBubbleOutlineIcon } from "./icons/CommentBubbleOutlineIcon";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import { useWorkticketModal } from "./context/WorkticketModalContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import MessageDialog from "../partnersList/dialog/messageDialog";
import { postDiscount } from "../../services/financialService";
import ErrorDialog from "../../components/ui/dialog/errorDialog";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import { getBillingList } from "../../contexts/billingApContext";
import { hasPermission, permissionFinancial } from "../../lib/permissions";
import GlobalUiContext from "../../contexts/globalUiContext";
export function WorkticketMenuActions({
  row,
  discount,
  number_worktickets,
  isReady,
  hideDiscounts = false,
  filterState = null,
  dispatchBilling = null,
}) {
  const classes = useStyles();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleToggleConfirmDialog = async (success = false) => {
    if (success) {
      if (filterState && dispatchBilling) {
        const filterData = filterState;
        dispatchBilling({
          type: "SET_LOADING",
          billingListLoading: true,
        });
        await getBillingList(dispatchBilling, filterData);
      }
    }
    setIsConfirmDialogOpen(!isConfirmDialogOpen);
  };
  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    setLoading(false);
  };
  const { hasRowsClickable, selected, currentTab } = useTableState();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  function toggleWorkticketModal(open, rowId) {
    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: rowId,
    });
  }
  const handleCloseSuccessDialog = async () => {
    setIsSuccessDialogOpen(false);
    setLoading(false);
    if (filterState && dispatchBilling) {
      const filterData = filterState;
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      await getBillingList(dispatchBilling, filterData);
    }
  };
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const toggleModal = (open) => {
    dispatchCommentAction({ type: "TOGGLE_COMMENT_DIALOG", open, discount });
  };
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);
  let selectedIds = selected?.length > 0 ? selected : [row.id];
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const toggleDiscountModal = (open, selected) => {
    dispatchDiscountAction({ type: "TOGGLE_CONTACT_DIALOG", open, selected });
  };
  const toggleDiscountModalEdit = (open, selected, editing) => {
    dispatchDiscountAction({
      type: "TOGGLE_CONTACT_DIALOG",
      open,
      selected,
      discount,
      editing,
    });
  };
  const toggleModalComment = (open, workticketId) => {
    dispatchCommentAction({
      type: "TOGGLE_COMMENT_DIALOG",
      open,
      workticketId,
    });
  };
  async function deleteDiscount(rowId) {
    let dataSend = {
      ap_record_ids: selected.length > 0 ? selected : [rowId],
      discount: 0,
    };
    try {
      // await createDiscount(dataSend);
      let response = await postDiscount(dataSend);
      setLoading(false);
      setIsSuccessDialogOpen(true);
      handleCloseConfirmDialog();
      // toggleModal(false);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
      setIsErrorDialogOpen(true);
      handleCloseConfirmDialog();
    }
  }
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const canEditDiscount =
    role?.category !== "subcontractor" &&
    hasPermission(permissionFinancial.AP_WORKTICKET_DISCOUNTS, permissions);
  const variousItems = [
    {
      label: "View WT",
      icon: <RemoveRedEyeOutlinedIcon fontSize="inherit" />,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row, true);
        toggleWorkticketModal(true, row.id);
        handleMenuClose();
      },
      // disabled: isSubmitting,
    },
  ];
  const readyItems = [
    {
      label: "View WT",
      icon: <RemoveRedEyeOutlinedIcon fontSize="inherit" />,
      // hide: number_worktickets > 1,
      onClick: () => {
        history.push(
          `/workticket/${row.workticket_id ? row.workticket_id : row.id}`
        );
        handleMenuClose();
      },
      // disabled: isSubmitting,
    },
    {
      label: "Add Discount",
      icon: <OutlineReceiptIcon fontSize="inherit" />,
      hide: hideDiscounts || discount > 0 || !canEditDiscount,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        toggleDiscountModal(true, selectedIds);
        handleMenuClose();
      },
    },
    {
      label: "Edit Discount",
      icon: <EditIcon fontSize="inherit" />,
      hide:
        selectedIds.length > 1 ||
        hideDiscounts ||
        discount <= 0 ||
        !canEditDiscount,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        // handleMenuClose();
        toggleDiscountModalEdit(true, selectedIds, true);
        handleMenuClose();
      },
      disabled: loading,
    },
    {
      label: "Add a Comment",
      icon: <CommentBubbleOutlineIcon fontSize="inherit" />,
      hide: number_worktickets > 1 || selectedIds.length > 1,
      disabled: selectedIds.length > 1,
      onClick: () => {
        // handleToggleDrawer("right", true, true, row);
        // toggleModal(true);
        toggleModalComment(true, row.id);
        handleMenuClose();
      },
    },
    {
      label: "Delete Discount",
      hide:
        selectedIds.length > 1 ||
        hideDiscounts ||
        discount <= 0 ||
        !canEditDiscount,
      icon: <DeleteBin fontSize="inherit" />,
      onClick: async () => {
        // handleDeleteUserDialogueOpen();
        setLoading(true);
        setIsConfirmDialogOpen(true);
        // await deleteDiscount(row.id);
        handleMenuClose();
      },
      // disabled: isSubmitting,
    },
  ];
  const menuItems = isReady ? readyItems : variousItems;
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleMenuClick(event);
        }}
        onFocus={(event) => event.stopPropagation()}
        aria-label="options"
      >
        <ThreeDots />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, icon, onClick, disabled, hide }) => (
          <MenuItem
            // disabled={true}
            key={label}
            onClick={onClick}
            //hide menu items if there are no rows selected
            style={{
              display: hide ? "none" : "flex",
            }}
            disabled={loading}
          >
            <Box className={classes.menuIcon}>{icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        message={"Are you sure you want to delete the discount?"}
        title="Delete Discount"
        handleClose={handleCloseConfirmDialog}
        handleConfirm={async () => {
          await deleteDiscount(row.id);
          // await handleToggleConfirmDialog(true);
        }}
      />
      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message="The discount has been successfully deleted."
        handleClose={handleCloseSuccessDialog}
      />
      <ErrorDialog
        open={isErrorDialogOpen}
        title="Error!"
        message={errorMessage}
        handleClose={handleCloseErrorDialog}
      />
    </Fragment>
  );
}
