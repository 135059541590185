import React, { Fragment } from "react";
import { WorkticketModal } from "../WorkticketModal";
import { TableProvider } from "../../../contexts/tableContext";
import { AddDiscountDialogProvider } from "./AddDiscountDialogueContext";
import { AddCommentDialogProvider } from "./AddCommentDialogueContext";
import { AddWorkticketCommentDialogue } from "../AddCommentDialogue";
import { WorkticketModalNoContext } from "../WorkticketModalNoContext";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorkticketModalContext = React.createContext();
const initialState = {
  isWorkticketModalOpen: false,
  activeRowId: null,
  subcontractor: null,
  fromWTPage: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_WORKTICKET_MODAL":
      return {
        ...state,
        isWorkticketModalOpen: action.open,
        activeRowId: action.open ? action.activeRowId : null,
        subcontractor: action.open ? action.subcontractor : null,
        fromWTPage: action.open ? action.fromWTPage : false,
      };
    default:
      return state;
  }
};
const WorkticketModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <WorkticketModalContext.Provider value={[state, dispatch]}>
      {children}
      {state.isWorkticketModalOpen && (
        <Fragment key={state.activeRowId}>
          <TableProvider>
            {!state.fromWTPage && (
              <AddCommentDialogProvider>
                <AddDiscountDialogProvider>
                  <WorkticketModal
                    rowId={state.activeRowId}
                    subcontractor={state.subcontractor}
                    takeSingleId={true}
                    fromWTPage={state.fromWTPage}
                  />
                </AddDiscountDialogProvider>
              </AddCommentDialogProvider>
            )}
            {state.fromWTPage && (
              <WorkticketModalNoContext
                rowId={state.activeRowId}
                subcontractor={state.subcontractor}
                takeSingleId={true}
                fromWTPage={state.fromWTPage}
              />
            )}
          </TableProvider>
        </Fragment>
      )}
    </WorkticketModalContext.Provider>
  );
};
const useWorkticketModal = () => {
  const context = React.useContext(WorkticketModalContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketModal must be used within a WorkticketModalProvider"
    );
  }
  return context;
};

export { WorkticketModalProvider, useWorkticketModal };
