import React from "react";
import { WorkticketViewProvider } from "../../../contexts/workticketViewContext";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const AddCommentDialogue = React.createContext();
const initialState = {
  isOpenAddDialog: false,
  workticketId: null,
  isEditingComment: false,
  commentId: null,
  commentText: "",
  successEdit: null,
  successDelete: null,
  isDeletingComment: false,
  isActionLoading: false,
  commentIdDelete: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_COMMENT_DIALOG":
      return {
        ...state,
        isOpenAddDialog: action.open,
        workticketId: action.workticketId,
        successEdit: null,
        successDelete: null,
      };
    case "TOGGLE_EDIT_COMMENT_DIALOG":
      return {
        ...state,
        isEditingComment: action.open,
        commentId: action.commentId,
        commentText: action.commentText,
        successEdit: null,
        successDelete: null,
      };
    case "TRIGGER_DELETE_COMMENT":
      return {
        ...state,
        commentIdDelete: action.commentIdDelete,
        isDeletingComment: action.open,
        successEdit: null,
        successDelete: null,
      };
    case "TOGGLE_SUCCESS_EDIT":
      return {
        ...state,
        successEdit: action.open,
      };
    case "TOGGLE_SUCCESS_DELETE":
      return {
        ...state,
        // successDelete: action.open,
        isDeletingComment: false,
        commentIdDelete: null,
      };
    case "SET_LOADING":
      return {
        ...state,
        isActionLoading: action.isActionLoading,
      };
    default:
      return state;
  }
};
const AddCommentDialogProvider = ({ children }) => (
  <AddCommentDialogue.Provider value={React.useReducer(reducer, initialState)}>
    <WorkticketViewProvider>{children}</WorkticketViewProvider>
  </AddCommentDialogue.Provider>
);
const useAddCommentModal = () => {
  const context = React.useContext(AddCommentDialogue);
  if (context === undefined) {
    throw new Error(
      "useAddCommentModal must be used within a AddCommentDialogProvider"
    );
  }
  return context;
};

export { AddCommentDialogProvider, useAddCommentModal };
