export function EditIconBg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#ECECEC" />
      <g clipPath="url(#clip0_160_4908)">
        <path
          d="M16.889 7.4977C16.3765 6.98514 15.5454 6.98514 15.0329 7.4977L14.4543 8.07628L16.3104 9.93244L16.889 9.35386C17.4016 8.8413 17.4016 8.01027 16.889 7.4977Z"
          fill="#747474"
        />
        <path
          d="M15.7801 10.4628L13.924 8.60661L7.84854 14.682C7.54015 14.9904 7.31345 15.3708 7.18894 15.7888L6.78905 17.1312C6.74974 17.2632 6.78592 17.4061 6.88328 17.5034C6.98065 17.6008 7.12354 17.637 7.2555 17.5977L8.59795 17.1978C9.01593 17.0733 9.39631 16.8466 9.7047 16.5382L15.7801 10.4628Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_160_4908">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6.02344 6.36328)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
