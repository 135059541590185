import { useQuery, useQueryClient } from "react-query";
import { getFinancialAPSubWorkticketTest } from "../../services/financialService";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import WorkticketField from "../../components/ui/Worktickets/WorkticketField";
import WorkticketComplianceChip from "../../components/ui/Worktickets/WorkticketComplianceChip";
import WorkticketSoloStatusChip from "../../components/ui/Worktickets/WorkticketSoloStatusChip";
import { dateFormatField } from "../../components/util/timeFormat";
import React from "react";
import { VariousTextComponent } from "./VariousTextComponent";
import Box from "@material-ui/core/Box";
import { WorkticketMenuActions } from "./WorkticketMenuActions";
import { CommentOutline } from "./icons/CommentOutline";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import { ChatBubbleSolid } from "./icons/ChatBubbleSolid";
import { formatNumber } from "../../components/util/stringFormat";

export function useWorkticketActions({ userId, subcontractor }) {
  const queryClient = useQueryClient();
  const dispatchTable = useTableDispatch();
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const columnsSubcontractor = [
    {
      id: "number",
      label: "Workticket",
      newId: "number",
      format: "link",
      path: (row) => (row.id ? `/workticket/${row.id}` : ``),
    },
    {
      id: "job",
      label: "Job",
      content: (row) => `${row.job_number} - ${row.job_description}`,
    },
    {
      id: "compliance",
      label: "Compliance",
      content: (row) => (
        <WorkticketComplianceChip
          onSite={row.compliance_onsite}
          onTime={row.compliance_ontime}
          hasDocuments={row.compliance_document}
        />
      ),
      disableSort: true,
    },
    {
      id: "status",
      label: "WT Status",
      content: (row, index) => (
        <>
          <WorkticketSoloStatusChip
            status={row?.workticket_status ?? ""}
            archived={0}
          />
        </>
      ),
      disableSort: true,
    },
    {
      id: "completed_date",
      label: "Due Date",
      content: (row) =>
        row?.completed_date ? dateFormatField(row?.completed_date) : `-`,
    },
    {
      id: "start_date",
      label: "Start Date",
      content: (row) =>
        row?.start_date ? dateFormatField(row?.start_date) : `-`,
    },

    {
      id: "sub_completed_date",
      label: "Verified Date",
      content: (row) =>
        row?.sub_completed_date
          ? dateFormatField(row?.sub_completed_date)
          : `-`,
    },
  ];
  const columnsReady = [
    {
      id: "number",
      label: "WT Summary",
      format: "link",
      path: (row) => (row.id ? `/workticket/${row.id}` : ``),
    },
    {
      id: "job_description",
      label: "Job Description",
    },
    {
      id: "usersAssign",
      label: "Assigned To",
    },
    {
      id: "compliance",
      label: "Compliance",
      content: (row) => (
        <WorkticketComplianceChip
          onSite={row.compliance_onsite}
          onTime={row.compliance_ontime}
          hasDocuments={row.compliance_document}
        />
      ),
      disableSort: true,
    },
    {
      // id: "after_discount",
      label: "Comments",
      content: (row) => (
        <>
          <Box
            onClick={() => {
              const toggleModalComment = (open, workticketId) => {
                dispatchCommentAction({
                  type: "TOGGLE_COMMENT_DIALOG",
                  open,
                  workticketId,
                });
              };
              toggleModalComment(true, row.id);
            }}
          >
            {row.has_comments > 0 ? <ChatBubbleSolid /> : <CommentOutline />}
          </Box>
        </>
      ),
    },
    {
      id: "status",
      label: "Status",
      content: (row) => (
        <WorkticketSoloStatusChip status={row?.status ?? ""} archived={0} />
      ),
      disableSort: true,
    },

    {
      id: "completed_date",
      label: "Due Date",
      content: (row) =>
        row?.completed_date ? dateFormatField(row?.completed_date) : `-`,
    },
    {
      id: "start_date",
      label: "Start Date",
      content: (row) =>
        row?.start_date ? dateFormatField(row?.start_date) : `-`,
    },

    {
      id: "sub_completed_date",
      label: "Verified Date",
      content: (row) =>
        row?.sub_completed_date
          ? dateFormatField(row?.sub_completed_date)
          : `-`,
    },
    {
      label: "Actions",
      content: (row) => (
        <Box key={row.id}>
          <WorkticketMenuActions
            row={row}
            hideDiscounts={true}
            // selected={selected}
            isReady={true}
          />
        </Box>
      ),
    },
  ];
  const { data, isLoading } = useQuery(
    ["workticketsVarious", userId],
    async () => {
      const { data } = await getFinancialAPSubWorkticketTest(userId);
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: subcontractor ? columnsSubcontractor : columnsReady,
      });
      dispatchTable({ type: "UPDATE_ROWS", rows: data.data.worktickets });

      return data;
    },
    {
      enabled: !!userId,
    }
  );
  return { data, isLoading, columnsReady, columnsSubcontractor };
}
